.heading i {
  color: #e94560;
  margin: 7px;
}

.flash {
  background: #f6f9fc;
  padding: 50px 0;
}

.discount {
  position: absolute;
  top: 0;
  left: 0;
  background: #e94560;
  padding: 3px 10px;
  font-size: 12px;
  border-radius: 50px;
  color: #fff;
  margin: 10px;
}

.slick-disabled {
  display: none;
}

/*---------product-----------*/
.product {
  max-height: 350px;
  background: #fff;
  padding: 20px;
  position: relative;
  border-radius: 8px;
  box-shadow: rgb(3 0 71 / 9%) 0px 1px 3px;
  margin: 10px;
}

.product h3 {
  font-weight: 400;
  font-size: 17px;
}

.product .rate i {
  font-size: 15px;
  color: #ffcd4e;
  margin: 5px 5px 5px 0;
}

.product .price {
  display: flex;
  justify-content: space-between;
  color: #e94560;
}

.price button {
  background: none;
  color: #e94560;
  font-size: 20px;
  transition: 0.5s;
  border: 1px solid rgb(3 0 71 / 9%);
  width: 35px;
  height: 35px;
  border-radius: 5px;
}

.price button:hover {
  cursor: pointer;
  background-color: #e94560;
  color: #fff;
}

.product-like {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  opacity: 0;
  transition: 0.5s;
}

.product-like label {
  background-color: #0f3460;
  padding: 1px 10px;
  font-size: 12px;
  color: white;
  border-radius: 50px;
}

.product-like i {
  font-size: 20px;
  margin: 10px 3px;
}

.product:hover .product-like {
  opacity: 1;
}

/*---------product-----------*/

/*----- control btn -------*/
.control-btn .next,
.control-btn .prev {
  position: absolute;
  top: 40%;
  width: 50px;
  height: 50px;
  line-height: 50px;
  background: #0f3460;
  border-radius: 50%;
  transform: 0.5s;
  z-index: 5;
}

.control-btn .prev {
  left: -20px;
}

.control-btn .next {
  right: -20px;
}

.control-btn .prev i,
.control-btn .next i {
  font-size: 20px;
  transition: 0.5s;
  color: #fff;
}

.control-btn .prev:hover,
.control-btn .next:hover {
  background: #e94560;
  color: white;
  cursor: pointer;
}

/*----- control btn -------*/

/* new code for button wish list */

.product-action-vertical {
    display: flex;
    flex-direction: column;
    position: absolute;
    /* right: .00001rem; */
    top: 2rem;
    background-color: transparent;
    z-index: 10;
    visibility: hidden;
    opacity: 0;
    transition: all .35s ease;
    transform: translateX(-15px)
}

.product:hover .product-action-vertical {
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
    /* right: .00001rem; */
}

.btn-product-icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    padding: 0;
    font-size: 1.4rem;
    line-height: 1;
    transition: all .35s ease;
    border-radius: 50%;
    /* color: #c96; */
    background-color: #fff
}

/* .btn-product-icon:before {
    font-family: "molla"
} */

.btn-product-icon span {
    display: none
}

.btn-product-icon:hover,
.btn-product-icon:focus {
    /* color: #fff; */
    background-color: #c96;
    /* margin-right: .00001rem; */
}

/* .btn-product-icon+.btn-product-icon {
    margin-top: 1rem
} */

/* .btn-wishlist {
    font-size: 1.6rem
} */

/* .btn-wishlist:before {
    content: '\f234'
} */

 .btn-wishlist {
    position: absolute;
    /* top: 3.4rem;
    right: 2rem;
    color: #ccc; */
    padding: 0;
    margin: 0;
    background: none
}

 /* .btn-wishlist::after {
    margin-left: 0
} */

 .btn-wishlist span {
    display: none
}

 .btn-wishlist:hover,
 .btn-wishlist:focus {
    /* color: #c96; */
    /* background: none; */
    background-color: #c96;
    transform: translateX(-.3rem);

}

.btn-expandable {
    position: relative
}

.btn-expandable span {
    position: absolute;
    right: 100%;
    text-transform: none;
    top: 0;
    bottom: 0;
    align-items: center;
    display: inline-flex;
    /* font-weight: 300; */
    font-size: .8rem;
    white-space: nowrap;
    letter-spacing: 0;
    min-width: 100px;
    padding-left: 1.8rem;
    padding-right: .4rem;
    color: #fff;
    background-color: #c96;
    transition: all .35s ease;
    opacity: 0;
    visibility: hidden;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    transform: translateX(15px);
    z-index: -1
}

.btn-expandable:hover,
.btn-expandable:focus {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.btn-expandable:hover span,
.btn-expandable:focus span {
    visibility: visible;
    opacity: 1;
    transform: translateX(0)
}
/* new code forbutton wish list */


@media (max-width: 480px) {
  .product {
    background: #fff;
    /* padding: 20px; */
    position: relative;
    border-radius: 8px;
    box-shadow: rgb(3 0 71 / 9%) 0px 1px 3px;
    margin: 10px;
  }

  .product img {
    max-width: 80%;
    max-height: 80%;
  }

  .product-like {
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px;
    opacity: 0;
    transition: 0.5s;
  }

  .product-like label {
    background-color: #0f3460;
    padding: 1px 10px;
    font-size: 12px;
    color: white;
    border-radius: 50px;
  }

  .product-like i {
    font-size: 20px;
    margin: 10px 3px;
  }

  .product:hover .product-like {
    opacity: 1;
  }
}