.tabList1.active {
    color: #e94560 !important;
    background-color: transparent !important;
    padding-left: 2rem;
}

.tab-pane {
    color: black !important;
}

/* .tabList1 {
    overflow: hidden;
    position: relative;
    font-weight: 300;
    font-size: 1.4rem;
    line-height: 1.5;
    padding: 1.4rem 0;
    color: #333;
    border-bottom: .1rem solid #ebebeb
}  */

.tabList1:hover {
    color:#e94560;
}

.tabList1::before {
    /* content: url('/public/images/arrow (2)i.png'); */
    content: '\27F6';
    display: block;
    font-size: 2rem;
    /* transform: scale(.04); */
    /* height:20px;
    width: 30px; */
    line-height: 1;
    position: absolute;
    /* top: 50%; */
    left: -15px;
    margin-top: -7.5px;
    transition: all .5s;
    opacity: 0
}

.tabList1.active {
    padding-left: 3rem;
    /* margin-right: 5rem; */
    font-weight: bold;
    transition: all .5s;
}

.tabList1.active:before {
    left: 0;
    opacity: 1;
    color:#e94560;
}
