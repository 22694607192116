footer {
    background-color: #0f3460;
    /* padding: 100px 0; */
    color: #fff;
}

footer h2 {
    margin-bottom: 30px;
    color: #e94560;
    font-weight: 800;
    font-style: italic;
}

footer .img {
    background-color: #0c2a4d;
    padding: 15px 20px;
    border-radius: 5px;
}

footer .img i {
    font-size: 20px;
    /* margin: 5px; */
}

