.no-items {
    color: #e94560;
    height: 130px;
}

.cart-items .cart-total {
    background: #fff;
    margin: 10px;
    padding: 20px;
    position: relative;
    border-radius: 8px;
    box-shadow: rgb(3 0 71 / 9%) 0px 1px 3px;
    width: 30%;
    margin-left: 30px;
    height: 130px;
}

.cart-items h4 span {
    color: #e94560;
}

.cartControl button {
    width: 40px;
    height: 40px;
    margin: 10px;
    border-radius: 5px;
    font-size: 20px;
}

.increment {
    background: none;
    border: 1px solid rgb(3 0 71 / 9%);
    color: #e94560;
}

.decrement {
    background: none;
    border: 1px solid rgb(3 0 71 / 9%);
    color: #e94560;
}

.cart-total h3 {
    color: #e94560;
}

.cart-total h2 {
    font-size: 18px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgb(3 0 71 / 9%);
    padding-bottom: 10px;
    color: #e94560;
}