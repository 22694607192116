.bigDiscount {
  background: #f6f9fc;
  padding: 50px 0;
}

.product2 {
    max-height: 400px;
    background: #fff;
    padding: 10px;
    position: relative;
    border-radius: 8px;
    box-shadow: rgb(3 0 71 / 9%) 0px 1px 3px;
    margin: 20px;
}

.product2 .price {
  color: #e94560;
}

.bigDiscountHeadingLeft img {
  width: 40px;
  height: 40px;
  /* margin-top: 5px; */
}

.bigDiscountHeadingRight {
  color: #e94560;
  font-weight: 500; 
}

.bigDiscountHeadingLeft i {
  /* text-align: right; */
  color: #e94560;
  /* margin: 5px; */
}

/* .bigDiscount span {
  color: #e94560;
  font-size: 15px;
  font-weight: 500;
} */
.bigDiscount .box img {
  width: 100%;
  height: 100%;
}

/*----- heading -------*/
/* .heading .heading-left i {
  font-size: 30px;
}
.heading .heading-left h2 {
  font-size: 25px;
  margin: 5px;
}
.heading .heading-right i,
.heading .heading-right {
  text-align: right;
  color: #e94560;
} */
/*----- heading -------*/
