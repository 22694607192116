/* Importing fonts from Google */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

/* Reseting */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

body {
    background: #ecf0f3;
}

.wrapper {
    max-width: 350px;
    min-height: 500px;
    /* margin: 80px auto; */
   
    padding: 40px 30px 30px 30px;
    background-color: #ecf0f3;
    border-radius: 15px;
    box-shadow: 13px 13px 20px #cbced1, -13px -13px 20px #fff;
}

.logo {
    /* width: 80px; */
    /* align-content: center; */
    margin: auto;
}

.logo img {
    width: 150px;
    /* padding: 20px; */
    height: 40px;
    object-fit: fill;
    border-radius: 10px;
    box-shadow: 0px 0px 3px #5f5f5f,
        0px 0px 0px 5px #ecf0f3,
        8px 8px 15px #a7aaa7,
        -8px -8px 15px #fff;
}

.wrapper .name {
    font-weight: 600;
    font-size: 1.4rem;
    letter-spacing: 1.3px;
    padding-left: 10px;
    color: #555;
}

.wrapper .form-field input {
    width: 100%;
    display: block;
    border: none;
    outline: none;
    background: none;
    font-size: 1.2rem;
    color: #666;
    padding: 10px 15px 10px 10px;
    /* border: 1px solid red; */
}

.wrapper .form-field {
    padding-left: 10px;
    margin-bottom: 20px;
    border-radius: 20px;
    box-shadow: inset 5px 5px 8px #cbced1, inset -5px -5px 8px #fff;
}

.wrapper .form-field .fas {
    color: #555;
}

.wrapper .btn {
    box-shadow: none;
    width: 100%;
    height: 40px;
    background-color: #03A9F4;
    color: #fff;
    border-radius: 25px;
    box-shadow: 3px 3px 3px #b1b1b1,
        -3px -3px 3px #fff;
    letter-spacing: 1.3px;
}

.wrapper .btn:hover {
    background-color: #039BE5;
}

.wrapper Link {
    text-decoration: none;
    font-size: 0.8rem;
    color: #03A9F4;
}

.wrapper Link:hover {
    color: #039BE5;
}

/* recovery email sending page */
.homeback
{
	position: relative;
	width: 100%;
	/* min-height:100vh; */
	text-align: center;
	align-items: center !important;
	justify-content: center !important;
	display: flex;

}
.homebox
{
	width: 380px;
	padding: 45px 35px 35px 35px;
}
.success-check
{
	color: #fc5130;
	margin: 0 auto;
}
.success-check i
{
	font-size: 3rem;

}
/* recovery email sending page */


@media(max-width: 380px) {
    .wrapper {
        margin: 30px 20px;
        padding: 40px 15px 15px 15px;
    }
}