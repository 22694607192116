@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap);
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100%;
  overflow-x: hidden;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */ 
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
body {
  font-family: "Poppins", sans-serif;
  background-color: #f6f9fc !important;
  max-width: 100%;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.no-items {
    color: #e94560;
    height: 130px;
}

.cart-items .cart-total {
    background: #fff;
    margin: 10px;
    padding: 20px;
    position: relative;
    border-radius: 8px;
    box-shadow: rgb(3 0 71 / 9%) 0px 1px 3px;
    width: 30%;
    margin-left: 30px;
    height: 130px;
}

.cart-items h4 span {
    color: #e94560;
}

.cartControl button {
    width: 40px;
    height: 40px;
    margin: 10px;
    border-radius: 5px;
    font-size: 20px;
}

.increment {
    background: none;
    border: 1px solid rgb(3 0 71 / 9%);
    color: #e94560;
}

.decrement {
    background: none;
    border: 1px solid rgb(3 0 71 / 9%);
    color: #e94560;
}

.cart-total h3 {
    color: #e94560;
}

.cart-total h2 {
    font-size: 18px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgb(3 0 71 / 9%);
    padding-bottom: 10px;
    color: #e94560;
}
/*---------Navbar Start----------*/
header {
  height: 60px;
  box-shadow: -1px 15px 17px -8px rgba(0, 0, 0, 0.1);
}

header ul li a:hover {
  color: #ff014f !important;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.search-box {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 50px;
}

.search-box span {
  opacity: 0.5;
  border-left: 2px solid rgba(0, 0, 0, 0.1);
}

.search-box div {
  width: 14%;
  opacity: 0.5;
}

.icon-circle {
  width: 50px;
  height: 50px;
  line-height: 50px;
  background: #f3f5f9;
  font-size: 20px
}

.active {
  color: #e94560 !important;
  background-color: transparent !important;
  /* padding-left: 0 !important; */
}

@media only screen and (max-width: 600px) {
  .icon-circle {
    width: 30px;
    height: 30px;
    line-height: 30px;
    background: #f3f5f9;
    font-size: 15px;
  }

  .cart {
    position: absolute;
    margin: 16px 0 0 0;
  }

  .navbar-toggler-icon {
    margin-bottom: 20px;
  }

  .container-fluid {
    margin-bottom: 20px;
  }

  .navbar-brand {
    margin-bottom: 20px;
  }

  .search-box {
    /* width: 14%; */
    /* opacity: 0.5; */
    margin: 50px 0 0 0;
  }

}

/*---------Navbar End----------*/


/*---------Category Start----------*/

.dropdown-menu img {
  width: 30px;
  height: 30px;
  margin-top: 10px;
  object-fit: contain;
}

.dropdown-menu span {
  margin: 5px;
  text-transform: capitalize;
}

.dropdown-menu .box {
  transition: 0.5s;
  padding: 0 20px;
}

.dropdown-menu .box:hover {
  background-color: #ffe1e6;
  cursor: pointer;
  color: #ff014f;
}

.dropdown-menu .box:hover img {
  color: #ff014f;
  -webkit-transform: translatey(4px);
          transform: translatey(4px);
}

/*---------Category End----------*/


.dropdown-menu .dropdown-submenu {
  display: none !important;
  /* position: absolute;
left: 100%;
top: -7px; */
}

.dropdown-menu>li:hover>.dropdown-submenu {
  display: block !important;
}

.dropdown-menu .arrow {
  /* content: ; */
  display: block;
  /* font-size: 2rem; */
  /* transform: scale(.04); */
  /* height:20px;
    width: 30px; */
  /* line-height: 1; */
  /* position: absolute; */
  /* top: 50%; */
  /* left: -15px;
  margin-top: -7.5px; */
  transition: all .5s;
  /* opacity: 0 */
}
.heading i {
  color: #e94560;
  margin: 7px;
}

.flash {
  background: #f6f9fc;
  padding: 50px 0;
}

.discount {
  position: absolute;
  top: 0;
  left: 0;
  background: #e94560;
  padding: 3px 10px;
  font-size: 12px;
  border-radius: 50px;
  color: #fff;
  margin: 10px;
}

.slick-disabled {
  display: none;
}

/*---------product-----------*/
.product {
  max-height: 350px;
  background: #fff;
  padding: 20px;
  position: relative;
  border-radius: 8px;
  box-shadow: rgb(3 0 71 / 9%) 0px 1px 3px;
  margin: 10px;
}

.product h3 {
  font-weight: 400;
  font-size: 17px;
}

.product .rate i {
  font-size: 15px;
  color: #ffcd4e;
  margin: 5px 5px 5px 0;
}

.product .price {
  display: flex;
  justify-content: space-between;
  color: #e94560;
}

.price button {
  background: none;
  color: #e94560;
  font-size: 20px;
  transition: 0.5s;
  border: 1px solid rgb(3 0 71 / 9%);
  width: 35px;
  height: 35px;
  border-radius: 5px;
}

.price button:hover {
  cursor: pointer;
  background-color: #e94560;
  color: #fff;
}

.product-like {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  opacity: 0;
  transition: 0.5s;
}

.product-like label {
  background-color: #0f3460;
  padding: 1px 10px;
  font-size: 12px;
  color: white;
  border-radius: 50px;
}

.product-like i {
  font-size: 20px;
  margin: 10px 3px;
}

.product:hover .product-like {
  opacity: 1;
}

/*---------product-----------*/

/*----- control btn -------*/
.control-btn .next,
.control-btn .prev {
  position: absolute;
  top: 40%;
  width: 50px;
  height: 50px;
  line-height: 50px;
  background: #0f3460;
  border-radius: 50%;
  -webkit-transform: 0.5s;
          transform: 0.5s;
  z-index: 5;
}

.control-btn .prev {
  left: -20px;
}

.control-btn .next {
  right: -20px;
}

.control-btn .prev i,
.control-btn .next i {
  font-size: 20px;
  transition: 0.5s;
  color: #fff;
}

.control-btn .prev:hover,
.control-btn .next:hover {
  background: #e94560;
  color: white;
  cursor: pointer;
}

/*----- control btn -------*/

/* new code for button wish list */

.product-action-vertical {
    display: flex;
    flex-direction: column;
    position: absolute;
    /* right: .00001rem; */
    top: 2rem;
    background-color: transparent;
    z-index: 10;
    visibility: hidden;
    opacity: 0;
    transition: all .35s ease;
    -webkit-transform: translateX(-15px);
            transform: translateX(-15px)
}

.product:hover .product-action-vertical {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    /* right: .00001rem; */
}

.btn-product-icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    padding: 0;
    font-size: 1.4rem;
    line-height: 1;
    transition: all .35s ease;
    border-radius: 50%;
    /* color: #c96; */
    background-color: #fff
}

/* .btn-product-icon:before {
    font-family: "molla"
} */

.btn-product-icon span {
    display: none
}

.btn-product-icon:hover,
.btn-product-icon:focus {
    /* color: #fff; */
    background-color: #c96;
    /* margin-right: .00001rem; */
}

/* .btn-product-icon+.btn-product-icon {
    margin-top: 1rem
} */

/* .btn-wishlist {
    font-size: 1.6rem
} */

/* .btn-wishlist:before {
    content: '\f234'
} */

 .btn-wishlist {
    position: absolute;
    /* top: 3.4rem;
    right: 2rem;
    color: #ccc; */
    padding: 0;
    margin: 0;
    background: none
}

 /* .btn-wishlist::after {
    margin-left: 0
} */

 .btn-wishlist span {
    display: none
}

 .btn-wishlist:hover,
 .btn-wishlist:focus {
    /* color: #c96; */
    /* background: none; */
    background-color: #c96;
    -webkit-transform: translateX(-.3rem);
            transform: translateX(-.3rem);

}

.btn-expandable {
    position: relative
}

.btn-expandable span {
    position: absolute;
    right: 100%;
    text-transform: none;
    top: 0;
    bottom: 0;
    align-items: center;
    display: inline-flex;
    /* font-weight: 300; */
    font-size: .8rem;
    white-space: nowrap;
    letter-spacing: 0;
    min-width: 100px;
    padding-left: 1.8rem;
    padding-right: .4rem;
    color: #fff;
    background-color: #c96;
    transition: all .35s ease;
    opacity: 0;
    visibility: hidden;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    -webkit-transform: translateX(15px);
            transform: translateX(15px);
    z-index: -1
}

.btn-expandable:hover,
.btn-expandable:focus {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.btn-expandable:hover span,
.btn-expandable:focus span {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0)
}
/* new code forbutton wish list */


@media (max-width: 480px) {
  .product {
    background: #fff;
    /* padding: 20px; */
    position: relative;
    border-radius: 8px;
    box-shadow: rgb(3 0 71 / 9%) 0px 1px 3px;
    margin: 10px;
  }

  .product img {
    max-width: 80%;
    max-height: 80%;
  }

  .product-like {
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px;
    opacity: 0;
    transition: 0.5s;
  }

  .product-like label {
    background-color: #0f3460;
    padding: 1px 10px;
    font-size: 12px;
    color: white;
    border-radius: 50px;
  }

  .product-like i {
    font-size: 20px;
    margin: 10px 3px;
  }

  .product:hover .product-like {
    opacity: 1;
  }
}
footer {
    background-color: #0f3460;
    /* padding: 100px 0; */
    color: #fff;
}

footer h2 {
    margin-bottom: 30px;
    color: #e94560;
    font-weight: 800;
    font-style: italic;
}

footer .img {
    background-color: #0c2a4d;
    padding: 15px 20px;
    border-radius: 5px;
}

footer .img i {
    font-size: 20px;
    /* margin: 5px; */
}


/* Importing fonts from Google */

/* Reseting */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

body {
    background: #ecf0f3;
}

.wrapper {
    max-width: 350px;
    min-height: 500px;
    /* margin: 80px auto; */
   
    padding: 40px 30px 30px 30px;
    background-color: #ecf0f3;
    border-radius: 15px;
    box-shadow: 13px 13px 20px #cbced1, -13px -13px 20px #fff;
}

.logo {
    /* width: 80px; */
    /* align-content: center; */
    margin: auto;
}

.logo img {
    width: 150px;
    /* padding: 20px; */
    height: 40px;
    object-fit: fill;
    border-radius: 10px;
    box-shadow: 0px 0px 3px #5f5f5f,
        0px 0px 0px 5px #ecf0f3,
        8px 8px 15px #a7aaa7,
        -8px -8px 15px #fff;
}

.wrapper .name {
    font-weight: 600;
    font-size: 1.4rem;
    letter-spacing: 1.3px;
    padding-left: 10px;
    color: #555;
}

.wrapper .form-field input {
    width: 100%;
    display: block;
    border: none;
    outline: none;
    background: none;
    font-size: 1.2rem;
    color: #666;
    padding: 10px 15px 10px 10px;
    /* border: 1px solid red; */
}

.wrapper .form-field {
    padding-left: 10px;
    margin-bottom: 20px;
    border-radius: 20px;
    box-shadow: inset 5px 5px 8px #cbced1, inset -5px -5px 8px #fff;
}

.wrapper .form-field .fas {
    color: #555;
}

.wrapper .btn {
    box-shadow: none;
    width: 100%;
    height: 40px;
    background-color: #03A9F4;
    color: #fff;
    border-radius: 25px;
    box-shadow: 3px 3px 3px #b1b1b1,
        -3px -3px 3px #fff;
    letter-spacing: 1.3px;
}

.wrapper .btn:hover {
    background-color: #039BE5;
}

.wrapper Link {
    text-decoration: none;
    font-size: 0.8rem;
    color: #03A9F4;
}

.wrapper Link:hover {
    color: #039BE5;
}

/* recovery email sending page */
.homeback
{
	position: relative;
	width: 100%;
	/* min-height:100vh; */
	text-align: center;
	align-items: center !important;
	justify-content: center !important;
	display: flex;

}
.homebox
{
	width: 380px;
	padding: 45px 35px 35px 35px;
}
.success-check
{
	color: #fc5130;
	margin: 0 auto;
}
.success-check i
{
	font-size: 3rem;

}
/* recovery email sending page */


@media(max-width: 380px) {
    .wrapper {
        margin: 30px 20px;
        padding: 40px 15px 15px 15px;
    }
}
.bigDiscount {
  background: #f6f9fc;
  padding: 50px 0;
}

.product2 {
    max-height: 400px;
    background: #fff;
    padding: 10px;
    position: relative;
    border-radius: 8px;
    box-shadow: rgb(3 0 71 / 9%) 0px 1px 3px;
    margin: 20px;
}

.product2 .price {
  color: #e94560;
}

.bigDiscountHeadingLeft img {
  width: 40px;
  height: 40px;
  /* margin-top: 5px; */
}

.bigDiscountHeadingRight {
  color: #e94560;
  font-weight: 500; 
}

.bigDiscountHeadingLeft i {
  /* text-align: right; */
  color: #e94560;
  /* margin: 5px; */
}

/* .bigDiscount span {
  color: #e94560;
  font-size: 15px;
  font-weight: 500;
} */
.bigDiscount .box img {
  width: 100%;
  height: 100%;
}

/*----- heading -------*/
/* .heading .heading-left i {
  font-size: 30px;
}
.heading .heading-left h2 {
  font-size: 25px;
  margin: 5px;
}
.heading .heading-right i,
.heading .heading-right {
  text-align: right;
  color: #e94560;
} */
/*----- heading -------*/

.flashHeadingLeft i {
  color: #e94560;
  /* margin: 10px; */
}

.flashHeadingRight {
  color: #e94560;
  font-weight: 500; 
}


.flash {
  background: #f6f9fc;
  padding: 40px 0 0 0;
}

.discount {
  position: absolute;
  top: 0;
  left: 0;
  background: #e94560;
  padding: 3px 10px;
  font-size: 12px;
  border-radius: 50px;
  color: #fff;
  margin: 10px;
}

.slick-disabled {
  display: none;
}

/*---------product-----------*/
.product {
  max-height: 350px;
  background: #fff;
  padding: 20px;
  position: relative;
  border-radius: 8px;
  box-shadow: rgb(3 0 71 / 9%) 0px 1px 3px;
  margin: 10px;
}

.product h3 {
  font-weight: 400;
  font-size: 17px;
}

.product .rate i {
  font-size: 15px;
  color: #ffcd4e;
  margin: 5px 5px 5px 0;
}

.product .price {
  display: flex;
  justify-content: space-between;
  color: #e94560;
}

.price button {
  background: none;
  color: #e94560;
  font-size: 20px;
  transition: 0.5s;
  border: 1px solid rgb(3 0 71 / 9%);
  width: 35px;
  height: 35px;
  border-radius: 5px;
}

.price button:hover {
  cursor: pointer;
  background-color: #e94560;
  color: #fff;
}

.product-like {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  opacity: 0;
  transition: 0.5s;
}

.product-like label {
  background-color: #0f3460;
  padding: 1px 10px;
  font-size: 12px;
  color: white;
  border-radius: 50px;
}

.product-like i {
  font-size: 20px;
  margin: 10px 3px;
}

.product:hover .product-like {
  opacity: 1;
}

/*---------product-----------*/

/*----- control btn -------*/
.control-btn .next,
.control-btn .prev {
  position: absolute;
  top: 40%;
  width: 50px;
  height: 50px;
  line-height: 50px;
  background: #0f3460;
  border-radius: 50%;
  -webkit-transform: 0.5s;
          transform: 0.5s;
  z-index: 5;
}

.control-btn .prev {
  left: -20px;
}

.control-btn .next {
  right: -20px;
}

.control-btn .prev i,
.control-btn .next i {
  font-size: 20px;
  transition: 0.5s;
  color: #fff;
}

.control-btn .prev:hover,
.control-btn .next:hover {
  background: #e94560;
  color: white;
  cursor: pointer;
}

/*----- control btn -------*/

/* new code for button wish list */

.product-action-vertical {
    display: flex;
    flex-direction: column;
    position: absolute;
    /* right: .00001rem; */
    top: 2rem;
    background-color: transparent;
    z-index: 10;
    visibility: hidden;
    opacity: 0;
    transition: all .35s ease;
    -webkit-transform: translateX(-15px);
            transform: translateX(-15px)
}

.product:hover .product-action-vertical {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    /* right: .00001rem; */
}

.btn-product-icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    padding: 0;
    font-size: 1.4rem;
    line-height: 1;
    transition: all .35s ease;
    border-radius: 50%;
    /* color: #c96; */
    background-color: #fff
}

/* .btn-product-icon:before {
    font-family: "molla"
} */

.btn-product-icon span {
    display: none
}

.btn-product-icon:hover,
.btn-product-icon:focus {
    /* color: #fff; */
    background-color: #c96;
    /* margin-right: .00001rem; */
}

/* .btn-product-icon+.btn-product-icon {
    margin-top: 1rem
} */

/* .btn-wishlist {
    font-size: 1.6rem
} */

/* .btn-wishlist:before {
    content: '\f234'
} */

 .btn-wishlist {
    position: absolute;
    /* top: 3.4rem;
    right: 2rem;
    color: #ccc; */
    padding: 0;
    margin: 0;
    background: none
}

 /* .btn-wishlist::after {
    margin-left: 0
} */

 .btn-wishlist span {
    display: none
}

 .btn-wishlist:hover,
 .btn-wishlist:focus {
    /* color: #c96; */
    /* background: none; */
    background-color: #c96;
    -webkit-transform: translateX(-.3rem);
            transform: translateX(-.3rem);

}

.btn-expandable {
    position: relative
}

.btn-expandable span {
    position: absolute;
    right: 100%;
    text-transform: none;
    top: 0;
    bottom: 0;
    align-items: center;
    display: inline-flex;
    /* font-weight: 300; */
    font-size: .8rem;
    white-space: nowrap;
    letter-spacing: 0;
    min-width: 100px;
    padding-left: 1.8rem;
    padding-right: .4rem;
    color: #fff;
    background-color: #c96;
    transition: all .35s ease;
    opacity: 0;
    visibility: hidden;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    -webkit-transform: translateX(15px);
            transform: translateX(15px);
    z-index: -1
}

.btn-expandable:hover,
.btn-expandable:focus {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.btn-expandable:hover span,
.btn-expandable:focus span {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0)
}
/* new code forbutton wish list */


@media (max-width: 480px) {
  .product {
    background: #fff;
    /* padding: 20px; */
    position: relative;
    border-radius: 8px;
    box-shadow: rgb(3 0 71 / 9%) 0px 1px 3px;
    margin: 10px;
  }

  .product img {
    max-width: 80%;
    max-height: 80%;
  }

  .product-like {
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px;
    opacity: 0;
    transition: 0.5s;
  }

  .product-like label {
    background-color: #0f3460;
    padding: 1px 10px;
    font-size: 12px;
    color: white;
    border-radius: 50px;
  }

  .product-like i {
    font-size: 20px;
    margin: 10px 3px;
  }

  .product:hover .product-like {
    opacity: 1;
  }
}
.carousel1 {
  background: #f6f9fc;
}

.btn-primary {
  background: #e94560;
}

.slick-arrow {
  /* visibility: hidden; */
}

.slick-next {
  display: none !important;
}

/* .slick-track {
  max-height: 75vh;
}

.slick-list {
  max-height: 75vh;
} */
.newArrivals {
    position: relative;
    background: #f6f9fc;
    padding: 50px 0;
}

/*----- heading -------*/
.newArrivalsHeadingLeft img {
  width: 40px;
  height: 40px;
  /* margin-top: 5px; */
}

.newArrivalsHeadingLeft i {
  /* text-align: right; */
  color: #e94560;
  /* margin: 5px; */
}
/*----- heading -------*/

/* .newArrivals h4 {
  font-weight: 400;
} */
.newArrivalsHeadingRight {
  color: #e94560;
  font-weight: 500; 
  /* font-size: 15px;*/
}

.newArrivalProduct .price {
  /* display: flex; */
  /* justify-content: space-between; */
  color: #e94560;
}
.newArrivals .box img {
  width: 100%;
  height: 100%;
}

.newArrivalProduct {
  max-height: 450px;
  background: #fff;
  padding: 20px;
  position: relative;
  border-radius: 8px;
  box-shadow: rgb(3 0 71 / 9%) 0px 1px 3px;
  margin-top: 10px;
}

/* .grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 30px;
}
.grid2 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
} */

/*----- heading -------*/
/* .heading .heading-left i {
  font-size: 30px;
}
.heading .heading-left h2 {
  font-size: 25px;
  margin: 5px;
}
.heading .heading-right i,
.heading .heading-right {
  text-align: right;
  color: #e94560;
} */
/*----- heading -------*/
.topCategoryHeadingLeft i {
    color: #e94560;
    /* margin: 5px; */
}

.topCategoryHeadingRight {
    color: #e94560;
    font-weight: 500; 
    /* margin: 5px; */
}

.topCategory {
    position: relative;
    background: #f6f9fc;
    padding: 1px 20px 0 0;
}

.topCategory img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.topCategory .nametop {
    position: relative;
    width: 100%;
}

.topCategory .nametop span {
    background: #0f3460;
    color: #fff;
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 50px;
    margin: 5px;
    position: absolute;
    /* top: 10px; */
}

.topCategory .nametop .tleft {
    left: 0;
}

.topCategory .nametop .tright {
    background-color: aliceblue;
    color: black;
    position: absolute;
    right: 0;
}

.product1 {
    max-height: 350px;
    background: #fff;
    padding: 20px;
    position: relative;
    border-radius: 8px;
    box-shadow: rgb(3 0 71 / 9%) 0px 1px 3px;
    margin: 20px;
}

.product1 .price {
  /* display: flex; */
  /* justify-content: space-between; */
  color: #e94560;
}

/*----- heading -------*/
/* .heading .heading-left i {
  font-size: 30px;
}
.heading .heading-left h2 {
  font-size: 25px;
  margin: 5px;
}
.heading .heading-right i,
.heading .heading-right {
  text-align: right;
  color: #e94560;
} */
/*----- heading -------*/
.tabList1.active {
    color: #e94560 !important;
    background-color: transparent !important;
    padding-left: 2rem;
}

.tab-pane {
    color: black !important;
}

/* .tabList1 {
    overflow: hidden;
    position: relative;
    font-weight: 300;
    font-size: 1.4rem;
    line-height: 1.5;
    padding: 1.4rem 0;
    color: #333;
    border-bottom: .1rem solid #ebebeb
}  */

.tabList1:hover {
    color:#e94560;
}

.tabList1::before {
    /* content: url('/public/images/arrow (2)i.png'); */
    content: '\27F6';
    display: block;
    font-size: 2rem;
    /* transform: scale(.04); */
    /* height:20px;
    width: 30px; */
    line-height: 1;
    position: absolute;
    /* top: 50%; */
    left: -15px;
    margin-top: -7.5px;
    transition: all .5s;
    opacity: 0
}

.tabList1.active {
    padding-left: 3rem;
    /* margin-right: 5rem; */
    font-weight: bold;
    transition: all .5s;
}

.tabList1.active:before {
    left: 0;
    opacity: 1;
    color:#e94560;
}

body {
    background-color: #eee;
    font-family: "Poppins", sans-serif
}

.singleProductCard {
    background-color: #fff;
    padding: 30px;
    border: none;
    border-radius: 10px;
}

.slick-list {
   align-items:center;
}

.slick-dots li {
    width: 110px;
    height: 110px;
    cursor: pointer;
}

.slick-slide .singleImg {
    border: 1px solid lightgrey;
    display: block;
    margin: auto;
    border-radius: 10px;
    margin-bottom: 100px;
    height: 250px;
    width: 250px;
}

.slick-thumb li img {
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    width: 110px;
    height: 110px;
    border: 1px solid lightgrey;
    border-radius: 10px;
}

.slick-thumb li.slick-active img {
    -webkit-filter: grayscale(0);
            filter: grayscale(0);
}

@media screen and (max-width: 480px) {
    /* .slick-slide .singleImg {
    border: 1px solid lightgrey;
    display: block;
    margin: auto;
    border-radius: 10px;
    margin-bottom: 100px;
    height: 250px;
    width: 250px; */

    .slick-dots {
    display: flex !important;
    }

    .slick-thumb li img {
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    width: 55px;
    height: 55px;
    border: 1px solid lightgrey;
    border-radius: 10px;
}
}
  


img {
    max-width: 100%;
    /* margin: 0 0 1.45rem; */
    padding: 0;
    border: none;
    align-items: center;
}

.stars i {
    color: #f6d151
}

.stars span {
    font-size: 13px
}

hr {
    color: #d4d4d4
}

.badge {
    padding: 5px !important;
    padding-bottom: 6px !important
}

.badge i {
    font-size: 10px
}

.profile-image {
    width: 35px
}

.comment-ratings i {
    font-size: 13px
}

.username {
    font-size: 12px
}

.comment-profile {
    line-height: 17px
}

.date span {
    font-size: 12px
}

.p-ratings i {
    color: #f6d151;
    font-size: 12px
}

.btn-long {
    padding-left: 35px;
    padding-right: 35px
}

.buttons {
    margin-top: 15px
}

.buttons .btn {
    height: 46px
}

.buttons .cart {
    border-color: #e94560;
    color: #e94560
}

.buttons .cart:hover {
    background-color: #eb7474 !important;
    color: #fff
}

.buttons .buy {
    color: #fff;
    background-color: #e94560;
    border-color: #e94560
}

.buttons .buy:focus,
.buy:active {
    color: #fff;
    background-color: #e94560;
    border-color: #e94560;
    box-shadow: none
}

.buttons .buy:hover {
    color: #fff;
    background-color: #eb7474;
    border-color: #eb7474
}

.buttons .wishlist {
    background-color: #fff;
    border-color: #e94560
}

.buttons .wishlist:hover {
    background-color: #eb7474;
    border-color: #eb7474;
    color: #fff
}

.buttons .wishlist:hover i {
    color: #fff
}

.buttons .wishlist i {
    color: #e94560
}

.comment-ratings i {
    color: #f6d151
}

.followers {
    font-size: 9px;
    color: #d6d4d4
}

.store-image {
    width: 42px
}

.dot {
    height: 10px;
    width: 10px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px
}

.bullet-text {
    font-size: 12px
}

.my-color {
    margin-top: 10px;
    margin-bottom: 10px
}

label.radio {
    cursor: pointer
}

label.radio input {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    pointer-events: none
}

label.radio span {
    border: 2px solid #8f37aa;
    display: inline-block;
    color: #8f37aa;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    text-transform: uppercase;
    transition: 0.5s all
}

label.radio .red {
    background-color: red;
    border-color: red
}

label.radio .blue {
    background-color: blue;
    border-color: blue
}

label.radio .green {
    background-color: green;
    border-color: green
}

label.radio .orange {
    background-color: orange;
    border-color: orange
}

label.radio input:checked+span {
    color: #fff;
    position: relative
}

label.radio input:checked+span::before {
    opacity: 1;
    content: '\2713';
    position: absolute;
    font-size: 13px;
    font-weight: bold;
    left: 4px
}

.card-body {
    padding: 0.3rem 0.3rem 0.2rem
}
