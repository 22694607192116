.topCategoryHeadingLeft i {
    color: #e94560;
    /* margin: 5px; */
}

.topCategoryHeadingRight {
    color: #e94560;
    font-weight: 500; 
    /* margin: 5px; */
}

.topCategory {
    position: relative;
    background: #f6f9fc;
    padding: 1px 20px 0 0;
}

.topCategory img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.topCategory .nametop {
    position: relative;
    width: 100%;
}

.topCategory .nametop span {
    background: #0f3460;
    color: #fff;
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 50px;
    margin: 5px;
    position: absolute;
    /* top: 10px; */
}

.topCategory .nametop .tleft {
    left: 0;
}

.topCategory .nametop .tright {
    background-color: aliceblue;
    color: black;
    position: absolute;
    right: 0;
}

.product1 {
    max-height: 350px;
    background: #fff;
    padding: 20px;
    position: relative;
    border-radius: 8px;
    box-shadow: rgb(3 0 71 / 9%) 0px 1px 3px;
    margin: 20px;
}

.product1 .price {
  /* display: flex; */
  /* justify-content: space-between; */
  color: #e94560;
}

/*----- heading -------*/
/* .heading .heading-left i {
  font-size: 30px;
}
.heading .heading-left h2 {
  font-size: 25px;
  margin: 5px;
}
.heading .heading-right i,
.heading .heading-right {
  text-align: right;
  color: #e94560;
} */
/*----- heading -------*/