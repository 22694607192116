.newArrivals {
    position: relative;
    background: #f6f9fc;
    padding: 50px 0;
}

/*----- heading -------*/
.newArrivalsHeadingLeft img {
  width: 40px;
  height: 40px;
  /* margin-top: 5px; */
}

.newArrivalsHeadingLeft i {
  /* text-align: right; */
  color: #e94560;
  /* margin: 5px; */
}
/*----- heading -------*/

/* .newArrivals h4 {
  font-weight: 400;
} */
.newArrivalsHeadingRight {
  color: #e94560;
  font-weight: 500; 
  /* font-size: 15px;*/
}

.newArrivalProduct .price {
  /* display: flex; */
  /* justify-content: space-between; */
  color: #e94560;
}
.newArrivals .box img {
  width: 100%;
  height: 100%;
}

.newArrivalProduct {
  max-height: 450px;
  background: #fff;
  padding: 20px;
  position: relative;
  border-radius: 8px;
  box-shadow: rgb(3 0 71 / 9%) 0px 1px 3px;
  margin-top: 10px;
}

/* .grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 30px;
}
.grid2 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
} */

/*----- heading -------*/
/* .heading .heading-left i {
  font-size: 30px;
}
.heading .heading-left h2 {
  font-size: 25px;
  margin: 5px;
}
.heading .heading-right i,
.heading .heading-right {
  text-align: right;
  color: #e94560;
} */
/*----- heading -------*/