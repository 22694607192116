.carousel1 {
  background: #f6f9fc;
}

.btn-primary {
  background: #e94560;
}

.slick-arrow {
  /* visibility: hidden; */
}

.slick-next {
  display: none !important;
}

/* .slick-track {
  max-height: 75vh;
}

.slick-list {
  max-height: 75vh;
} */