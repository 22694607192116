/*---------Navbar Start----------*/
header {
  height: 60px;
  box-shadow: -1px 15px 17px -8px rgba(0, 0, 0, 0.1);
}

header ul li a:hover {
  color: #ff014f !important;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.search-box {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 50px;
}

.search-box span {
  opacity: 0.5;
  border-left: 2px solid rgba(0, 0, 0, 0.1);
}

.search-box div {
  width: 14%;
  opacity: 0.5;
}

.icon-circle {
  width: 50px;
  height: 50px;
  line-height: 50px;
  background: #f3f5f9;
  font-size: 20px
}

.active {
  color: #e94560 !important;
  background-color: transparent !important;
  /* padding-left: 0 !important; */
}

@media only screen and (max-width: 600px) {
  .icon-circle {
    width: 30px;
    height: 30px;
    line-height: 30px;
    background: #f3f5f9;
    font-size: 15px;
  }

  .cart {
    position: absolute;
    margin: 16px 0 0 0;
  }

  .navbar-toggler-icon {
    margin-bottom: 20px;
  }

  .container-fluid {
    margin-bottom: 20px;
  }

  .navbar-brand {
    margin-bottom: 20px;
  }

  .search-box {
    /* width: 14%; */
    /* opacity: 0.5; */
    margin: 50px 0 0 0;
  }

}

/*---------Navbar End----------*/


/*---------Category Start----------*/

.dropdown-menu img {
  width: 30px;
  height: 30px;
  margin-top: 10px;
  object-fit: contain;
}

.dropdown-menu span {
  margin: 5px;
  text-transform: capitalize;
}

.dropdown-menu .box {
  transition: 0.5s;
  padding: 0 20px;
}

.dropdown-menu .box:hover {
  background-color: #ffe1e6;
  cursor: pointer;
  color: #ff014f;
}

.dropdown-menu .box:hover img {
  color: #ff014f;
  transform: translatey(4px);
}

/*---------Category End----------*/


.dropdown-menu .dropdown-submenu {
  display: none !important;
  /* position: absolute;
left: 100%;
top: -7px; */
}

.dropdown-menu>li:hover>.dropdown-submenu {
  display: block !important;
}

.dropdown-menu .arrow {
  /* content: ; */
  display: block;
  /* font-size: 2rem; */
  /* transform: scale(.04); */
  /* height:20px;
    width: 30px; */
  /* line-height: 1; */
  /* position: absolute; */
  /* top: 50%; */
  /* left: -15px;
  margin-top: -7.5px; */
  transition: all .5s;
  /* opacity: 0 */
}