@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
body {
    background-color: #eee;
    font-family: "Poppins", sans-serif
}

.singleProductCard {
    background-color: #fff;
    padding: 30px;
    border: none;
    border-radius: 10px;
}

.slick-list {
   align-items:center;
}

.slick-dots li {
    width: 110px;
    height: 110px;
    cursor: pointer;
}

.slick-slide .singleImg {
    border: 1px solid lightgrey;
    display: block;
    margin: auto;
    border-radius: 10px;
    margin-bottom: 100px;
    height: 250px;
    width: 250px;
}

.slick-thumb li img {
    filter: grayscale(100%);
    width: 110px;
    height: 110px;
    border: 1px solid lightgrey;
    border-radius: 10px;
}

.slick-thumb li.slick-active img {
    filter: grayscale(0);
}

@media screen and (max-width: 480px) {
    /* .slick-slide .singleImg {
    border: 1px solid lightgrey;
    display: block;
    margin: auto;
    border-radius: 10px;
    margin-bottom: 100px;
    height: 250px;
    width: 250px; */

    .slick-dots {
    display: flex !important;
    }

    .slick-thumb li img {
    filter: grayscale(100%);
    width: 55px;
    height: 55px;
    border: 1px solid lightgrey;
    border-radius: 10px;
}
}
  


img {
    max-width: 100%;
    /* margin: 0 0 1.45rem; */
    padding: 0;
    border: none;
    align-items: center;
}

.stars i {
    color: #f6d151
}

.stars span {
    font-size: 13px
}

hr {
    color: #d4d4d4
}

.badge {
    padding: 5px !important;
    padding-bottom: 6px !important
}

.badge i {
    font-size: 10px
}

.profile-image {
    width: 35px
}

.comment-ratings i {
    font-size: 13px
}

.username {
    font-size: 12px
}

.comment-profile {
    line-height: 17px
}

.date span {
    font-size: 12px
}

.p-ratings i {
    color: #f6d151;
    font-size: 12px
}

.btn-long {
    padding-left: 35px;
    padding-right: 35px
}

.buttons {
    margin-top: 15px
}

.buttons .btn {
    height: 46px
}

.buttons .cart {
    border-color: #e94560;
    color: #e94560
}

.buttons .cart:hover {
    background-color: #eb7474 !important;
    color: #fff
}

.buttons .buy {
    color: #fff;
    background-color: #e94560;
    border-color: #e94560
}

.buttons .buy:focus,
.buy:active {
    color: #fff;
    background-color: #e94560;
    border-color: #e94560;
    box-shadow: none
}

.buttons .buy:hover {
    color: #fff;
    background-color: #eb7474;
    border-color: #eb7474
}

.buttons .wishlist {
    background-color: #fff;
    border-color: #e94560
}

.buttons .wishlist:hover {
    background-color: #eb7474;
    border-color: #eb7474;
    color: #fff
}

.buttons .wishlist:hover i {
    color: #fff
}

.buttons .wishlist i {
    color: #e94560
}

.comment-ratings i {
    color: #f6d151
}

.followers {
    font-size: 9px;
    color: #d6d4d4
}

.store-image {
    width: 42px
}

.dot {
    height: 10px;
    width: 10px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px
}

.bullet-text {
    font-size: 12px
}

.my-color {
    margin-top: 10px;
    margin-bottom: 10px
}

label.radio {
    cursor: pointer
}

label.radio input {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    pointer-events: none
}

label.radio span {
    border: 2px solid #8f37aa;
    display: inline-block;
    color: #8f37aa;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    text-transform: uppercase;
    transition: 0.5s all
}

label.radio .red {
    background-color: red;
    border-color: red
}

label.radio .blue {
    background-color: blue;
    border-color: blue
}

label.radio .green {
    background-color: green;
    border-color: green
}

label.radio .orange {
    background-color: orange;
    border-color: orange
}

label.radio input:checked+span {
    color: #fff;
    position: relative
}

label.radio input:checked+span::before {
    opacity: 1;
    content: '\2713';
    position: absolute;
    font-size: 13px;
    font-weight: bold;
    left: 4px
}

.card-body {
    padding: 0.3rem 0.3rem 0.2rem
}